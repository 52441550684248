import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { RefreshCircle } from '@styled-icons/ionicons-solid';

import { Wrapper } from './CheckingForUserModalStyled';
import Paragraph from '../Paragraph/Paragraph';

const CheckingForUserModal = ({ show }) => {
    return (
        <CSSTransition in={show} timeout={200} classNames="appear" unmountOnExit>
            <Wrapper>
                <RefreshCircle />
                <Paragraph textAlign="center" color="onOrange" fontWeight={500} fontSize={0.9}>
                    Checking for active user session
                </Paragraph>
            </Wrapper>
        </CSSTransition>
    );
};

CheckingForUserModal.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default CheckingForUserModal;
