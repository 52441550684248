import styled from 'styled-components';

const StyledDiv = styled.div`
    &.SignInWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
    }
    &.InputsWrapper {
        padding: 3rem 0;
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
    }
    &.SignInHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const StyledForm = styled.form`
    &.SignInForm {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
    }
`;

export { StyledDiv, StyledForm };
