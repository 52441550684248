import styled from 'styled-components';
import { defaultBoxShadow } from '../../constants/mixins';

const StyledSelect = styled.select`
    padding: 1rem;
    border: none;
    font-size: 1.2rem;
    flex: 1;
    color: ${(props) => props.theme.onWhite};
    appearance: none;
    outline: none;
    background-color: ${(props) => props.theme.white};
    box-sizing: border-box;

    &::-ms-expand {
        display: none;
    }
`;

const StyledLabel = styled.label`
    &.SelectWrapper {
        position: relative;
        border-radius: 1rem;
        z-index: 1;
        display: flex;
        flex-direction: row;
        ${defaultBoxShadow}
        transition: all 50ms ease-in-out;
        border-style: solid;
        border-color: ${(props) => (props.focused ? props.theme.onWhite : props.theme.white)};
        border-width: 2px;
        background-color: ${(props) => props.theme.white};

        svg {
            width: 2rem;
            height: 2rem;
            color: ${(props) => props.theme.onWhite};
        }
    }
`;

const StyledDiv = styled.div`
    &.IconWrapper {
        margin: auto 0;
        padding-left: 1rem;
    }
    &.ChevronDownWrapper {
        margin: auto 0;
        padding-right: 1rem;
    }
`;

export { StyledDiv, StyledSelect, StyledLabel };
