import { Formik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Earth, Mail, Business, Call, LockClosed, Ear, FingerPrint, Construct } from '@styled-icons/ionicons-solid';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { StyledDiv, StyledForm } from './SignUpStyled';
import Button from '../../components/Button/Button';
import { getFormikError } from '../../helperFunctions/formik';
import Input from '../../components/Input/Input';
import { signUp } from '../../helperFunctions/signUp';
import { useYupDefaults } from '../../hooks/useYupDefaults';
import Select from '../../components/Select/Select';
import { countryList } from '../../constants/countries';
import { PopupContext } from '../../constants/contexts';

const DefaultSignUp = () => {
    const { setPopup } = useContext(PopupContext);
    const { search } = useLocation();
    const [initialValues, setInitialValues] = useState({});
    const { password } = useYupDefaults();

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                companyName: Yup.string().required('Company name is required'),
                companyEmail: Yup.string().required('Company email is required'),
                phoneNumber: Yup.string().required('Phone number is required'),
                country: Yup.string().required('Country is required'),
                password,
                cvr: Yup.string().min(9, 'Must be exactly 9 digits').max(9, 'Must be exactly 9 digits'),
            }),
        [password]
    );

    const handleError = (message) => {
        // alert user with error message
        setPopup({
            show: true,
            title: 'Error',
            description: message,
        });
    };

    const handleSignUp = async (values) => {
        try {
            const body = {
                password: values.password,
                token: values.token,
                reference: values.reference,
                companyName: values.companyName,
                companyEmail: values.companyEmail,
                phoneNumber: values.phoneNumber,
                country: values.country || 'no',
                mode: process.env.REACT_APP_STAGE === 'production' ? 'prod' : values.mode,
            };

            if (values.cvr !== '') {
                body.cvr = +values.cvr;
            }

            const data = await signUp(body);

            if (data.success) {
                // redirect user to redirect project
                if (data.userType) {
                    window.location.href = `/${data.userType}/index.html`;
                } else {
                    window.location.href = '/redirect/index.html';
                }
            } else {
                handleError(data.message);
            }
        } catch (err) {
            // display the error message to the user -> it gives valuable info about the
            handleError(err.response.data.message);
        }
    };

    useEffect(() => {
        const prefillValues = () => {
            const query = new URLSearchParams(search);

            setInitialValues({
                companyName: query.get('companyName') || '',
                companyEmail: query.get('cEmail') || '',
                phoneNumber: query.get('phoneNumber') || '',
                password: query.get('password') || '',
                country: query.get('country') || 'no',
                reference: query.get('reference') || '',
                cvr: query.get('cvr') || '',
                mode: query.get('mode') || 'prod',
            });
        };

        prefillValues();
    }, [search]);

    return (
        <Formik onSubmit={handleSignUp} validationSchema={validationSchema} enableReinitialize initialValues={initialValues}>
            {({ values, handleChange, handleSubmit, handleBlur, touched, errors, isSubmitting }) => {
                return (
                    <StyledForm onSubmit={handleSubmit} className="SignUpForm">
                        <StyledDiv className="InputsWrapper">
                            <Input
                                autoComplete="organization"
                                placeholder="Enter your company name"
                                icon={<Business />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyName || ''}
                                error={getFormikError({ touched, errors, path: ['companyName'] })}
                                name="companyName"
                                type="text"
                            />
                            <Input
                                autoComplete="email"
                                placeholder="Enter your company email address"
                                icon={<Mail />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyEmail || ''}
                                error={getFormikError({ touched, errors, path: ['companyEmail'] })}
                                name="companyEmail"
                                type="email"
                            />
                            <Input
                                autoComplete="tel"
                                placeholder="Enter your phone number"
                                icon={<Call />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phoneNumber || ''}
                                error={getFormikError({ touched, errors, path: ['phoneNumber'] })}
                                name="phoneNumber"
                                type="text"
                            />
                            <Select
                                autoComplete="country"
                                name="country"
                                value={values.country || 'no'}
                                onChange={handleChange}
                                icon={<Earth />}
                                options={countryList}
                            />
                            <Input
                                autoComplete="new-password"
                                placeholder="Enter your password"
                                icon={<LockClosed />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password || ''}
                                error={getFormikError({ touched, errors, path: ['password'] })}
                                name="password"
                                type="password"
                            />
                            <Input
                                autoComplete="off"
                                placeholder="Enter your reference"
                                icon={<Ear />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.reference || ''}
                                error={getFormikError({ touched, errors, path: ['reference'] })}
                                name="reference"
                                type="text"
                            />
                            <Input
                                autoComplete="off"
                                placeholder="Enter your company CVR"
                                icon={<FingerPrint />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cvr || ''}
                                error={getFormikError({ touched, errors, path: ['cvr'] })}
                                name="cvr"
                                type="number"
                            />
                            {process.env.REACT_APP_STAGE !== 'production' && (
                                <Select
                                    name="mode"
                                    value={values.mode}
                                    onChange={handleChange}
                                    icon={<Construct />}
                                    options={[
                                        { label: 'Production', value: 'prod' },
                                        { label: 'Development', value: 'dev' },
                                    ]}
                                />
                            )}
                        </StyledDiv>
                        <Button fontWeight={500} loading={isSubmitting} type="submit">
                            Sign up
                        </Button>
                    </StyledForm>
                );
            }}
        </Formik>
    );
};

// TODO implement tos agreement

export default DefaultSignUp;
