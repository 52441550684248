import { capitalizeFirstCharacter } from './text';

export const checkForTextColorInvert = (color = '') => {
    if (color.startsWith('on')) {
        return color.replace('on', '').toLowerCase();
    }

    return `on${capitalizeFirstCharacter(color)}`;
};

export const hexToRgbA = (hex, opacity = 1) => {
    let c;

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }

    return hex;
};

export const adjustColor = (color, amount) => {
    return (
        color &&
        `#${color
            .replace(/^#/, '')
            .replace(/../g, (e) => `0${Math.min(255, Math.max(0, parseInt(e, 16) + amount)).toString(16)}`.substr(-2))}`
    );
};
