import React from 'react';
import PropTypes from 'prop-types';
import { Backspace } from '@styled-icons/ionicons-solid';

import { StyledDiv, StyledButton, StyledSpan, StyledImage } from './NumPadStyled';
import Paragraph from '../Paragraph/Paragraph';
import Loading from '../Loading/Loading';

const dialerButtons = [
    { number: 1, alphabet: '' },
    { number: 2, alphabet: 'ABC' },
    { number: 3, alphabet: 'DEF' },
    { number: 4, alphabet: 'GHI' },
    { number: 5, alphabet: 'JKL' },
    { number: 6, alphabet: 'MNO' },
    { number: 7, alphabet: 'PQRS' },
    { number: 8, alphabet: 'TUV' },
    { number: 9, alphabet: 'WXYZ' },
];

const NumPad = ({ value, countryCode, flag, onChange, onEnter, loading, icon, type, showAlphabet, disabledEnter, error, enterText }) => {
    const onNumberClick = (number) => {
        if (type === 'tel' ? value.length < 8 : value.length < 4 && !loading) onChange(value + String(number));
    };

    const onBackspaceClick = () => {
        if (!loading) {
            onChange(value.substring(0, value.length - 1));
        }
    };

    const onClear = () => {
        if (!loading) {
            onChange('');
        }
    };

    return (
        <StyledDiv className="NumPadWrapper">
            <StyledDiv className="InnerTransformWrapper">
                <StyledDiv className={`NumPadInputWrapper ${error ? 'error' : ''} ${type}`}>
                    <StyledDiv className={`NumpadInputLeft ${type}`}>
                        {icon}
                        {flag && <StyledImage className="NumPadFlag" src={flag} />}
                        {countryCode && (
                            <Paragraph color="onWhite" fontWeight={700} fontSize={2.5}>
                                {countryCode}
                            </Paragraph>
                        )}
                        <Paragraph color="onWhite" fontWeight={700} fontSize={2.5}>
                            {type !== 'tel' ? value : `${value.substring(0, 3)} ${value.substring(3, 5)} ${value.substring(5, 8)}`}
                        </Paragraph>
                    </StyledDiv>
                    <StyledButton onClick={onBackspaceClick} className="BackspaceButton">
                        <Backspace />
                    </StyledButton>
                </StyledDiv>
                <StyledDiv className="Dialer">
                    {dialerButtons.map((e) => {
                        return (
                            <StyledButton onClick={() => onNumberClick(e.number)} color="white" className="DialerButton" key={e.number}>
                                <StyledSpan className={`DialerButtonNumber ${!showAlphabet ? 'singleLine' : ''}`}>{e.number}</StyledSpan>
                                {showAlphabet && <StyledSpan className="DialerButtonAlphabet"> {e.alphabet}</StyledSpan>}
                            </StyledButton>
                        );
                    })}
                    <StyledButton onClick={onClear} color="orange" className="DialerButton singleLine">
                        Clear
                    </StyledButton>
                    <StyledButton onClick={() => onNumberClick(0)} color="white" className="DialerButton singleLine">
                        {0}
                    </StyledButton>
                    <StyledButton
                        onClick={(e) => {
                            if (!loading && !disabledEnter) {
                                onEnter(e);
                            }
                        }}
                        color="green"
                        className="DialerButton singleLine"
                    >
                        {loading ? <Loading color="onGreen" /> : enterText}
                    </StyledButton>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    );
};

NumPad.defaultProps = {
    countryCode: '',
    flag: null,
    loading: false,
    icon: null,
    type: 'tel', // code
    showAlphabet: true,
    value: '',
    disabledEnter: false,
    error: false,
    enterText: 'Enter',
};

NumPad.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    countryCode: PropTypes.string,
    flag: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    icon: PropTypes.element,
    type: PropTypes.string,
    showAlphabet: PropTypes.bool,
    disabledEnter: PropTypes.bool,
    error: PropTypes.bool,
    enterText: PropTypes.string,
};

export default NumPad;
