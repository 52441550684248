import { authAxios } from '../constants/axios';
import { signInToFirebase } from '../firebase/signInToFirebase';

export const signUp = async (body) => {
    const { data } = await authAxios.post('/signUp', body, { timeout: 1000 * 60 });

    if (data.success) {
        await signInToFirebase(data.accessToken);

        return data;
    }

    return Promise.reject(data.message);
};
