import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@styled-icons/ionicons-solid';

import { StyledDiv } from './CloseButtonStyled';

const CloseButton = ({ size, iconSize, color, onClick, icon }) => {
    return (
        <StyledDiv className="CloseButton" onClick={onClick} iconSize={iconSize === null ? size * 0.4 : iconSize} size={size} color={color}>
            {icon || <Close />}
        </StyledDiv>
    );
};

CloseButton.defaultProps = {
    size: 2.5,
    iconSize: null,
    color: 'onWhite',
    icon: null,
};

CloseButton.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.element,
    iconSize: PropTypes.number,
};

export default CloseButton;
