import styled from 'styled-components';

// TODO ADD SUPPORT FOR DURATION

const BackdropDiv = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.modalBackground};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    z-index: ${(props) => props.zIndex};
    opacity: 0.7;

    &.appear-enter {
        opacity: 0;
    }
    &.appear-enter-active {
        opacity: 0.7;
        transition: opacity 300ms;
    }
    &.appear-exit {
        opacity: 0.7;
    }
    &.appear-exit-active {
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }
`;

export { BackdropDiv };
