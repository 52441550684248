import styled from 'styled-components';

const StyledDiv = styled.div`
    &.PageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.PinCodeWrapper {
        padding: 1rem;
        display: grid;
        grid-auto-flow: row;
    }
    &.ErrorWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
    }
    &.UsersWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
        flex: 1;
    }
    &.User {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${(props) => props.theme.onWhite};
        border-radius: 1rem;
        padding: 1rem;
        cursor: pointer;
        transition: all 100ms ease-in-out;

        &:hover {
            transform: scale(0.97);
        }
    }
`;

export { StyledDiv };
