import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledDiv } from './ButtonStyled';
import Loading from '../Loading/Loading';
import { checkForTextColorInvert } from '../../helperFunctions/colors';
import { calculateByPriority } from '../../helperFunctions/ui';

const Button = ({
    children,
    color,
    onClick,
    loading,
    disabled,
    reference,
    fontSize,
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingRight,
    paddingLeft,
    fontWeight,
    paddingBottom,
    ...rest
}) => {
    const onButtonPressHandler = (e) => {
        if (!disabled && !loading && onClick) {
            onClick(e);
        }
    };

    const component = (
        <StyledButton
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            fontSize={fontSize}
            ref={reference}
            disabled={disabled || loading}
            onClick={onButtonPressHandler}
            color={color}
            paddingRight={calculateByPriority([paddingRight, paddingHorizontal, padding])}
            paddingLeft={calculateByPriority([paddingLeft, paddingHorizontal, padding])}
            paddingTop={calculateByPriority([paddingTop, paddingVertical, padding])}
            paddingBottom={calculateByPriority([paddingBottom, paddingVertical, padding])}
            fontWeight={fontWeight}
        >
            {children}
            {loading ? (
                <StyledDiv color={color} className="LoadingWrapper">
                    <Loading color={checkForTextColorInvert(color)} />
                </StyledDiv>
            ) : null}
        </StyledButton>
    );

    return component;
};

Button.defaultProps = {
    color: 'onWhite',
    loading: false,
    disabled: false,
    reference: null,
    fontSize: 1.2,
    padding: 1,
    paddingVertical: null,
    paddingHorizontal: null,
    paddingTop: null,
    paddingRight: null,
    paddingLeft: null,
    paddingBottom: null,
    fontWeight: 400,
    onClick: null,
};

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(HTMLButtonElement) })]),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    fontWeight: PropTypes.number,
    fontSize: PropTypes.number,
    padding: PropTypes.number,
    paddingVertical: PropTypes.number,
    paddingHorizontal: PropTypes.number,
    paddingTop: PropTypes.number,
    paddingRight: PropTypes.number,
    paddingLeft: PropTypes.number,
    paddingBottom: PropTypes.number,
};

export default Button;
