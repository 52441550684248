import { Formik } from 'formik';
import React, { useContext, useMemo } from 'react';
import { Ear, FingerPrint } from '@styled-icons/ionicons-solid';
import * as Yup from 'yup';

import { StyledDiv, StyledForm } from './SignUpStyled';
import Button from '../../components/Button/Button';
import { getFormikError } from '../../helperFunctions/formik';
import Input from '../../components/Input/Input';
import { signUp } from '../../helperFunctions/signUp';
import { PopupContext } from '../../constants/contexts';

const TokenSignUp = () => {
    const { setPopup } = useContext(PopupContext);

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                token: Yup.string().required('Token is required'),
            }),
        []
    );

    const handleError = (message) => {
        // alert user with error message
        setPopup({
            show: true,
            title: 'Error',
            description: message,
        });
    };

    const handleSignUp = async (values) => {
        try {
            const body = {
                token: values.token,
                reference: values.reference,
            };

            const data = await signUp(body);

            if (data.success) {
                // redirect user to redirect project
                if (data.userType) {
                    window.location.href = `/${data.userType}/index.html`;
                } else {
                    window.location.href = '/redirect/index.html';
                }
            } else {
                handleError(data.message);
            }
        } catch (err) {
            // display the error message to the user -> it gives valuable info about the
            handleError(err.response.data.message);
        }
    };

    return (
        <Formik onSubmit={handleSignUp} validationSchema={validationSchema} initialValues={{ token: '', reference: '' }}>
            {({ values, handleChange, handleSubmit, handleBlur, touched, errors, isSubmitting }) => {
                return (
                    <StyledForm onSubmit={handleSubmit} className="SignUpForm">
                        <StyledDiv className="InputsWrapper">
                            <Input
                                autoComplete="off"
                                placeholder="Enter token"
                                icon={<FingerPrint />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.token || ''}
                                error={getFormikError({ touched, errors, path: ['token'] })}
                                name="token"
                                type="text"
                            />
                            <Input
                                autoComplete="off"
                                placeholder="Enter your reference"
                                icon={<Ear />}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.reference || ''}
                                error={getFormikError({ touched, errors, path: ['reference'] })}
                                name="reference"
                                type="text"
                            />
                        </StyledDiv>
                        <Button fontWeight={500} loading={isSubmitting} type="submit">
                            Sign up
                        </Button>
                    </StyledForm>
                );
            }}
        </Formik>
    );
};

export default TokenSignUp;
