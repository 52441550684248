import axios from 'axios';

export default axios.create({
    baseURL: `https://api.ns3.${process.env.REACT_APP_STAGE === 'production' ? 'no' : 'dev'}`,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        fullRequestPath: window.location.href,
    },
});

export const authAxios = axios.create({
    baseURL: `https://auth.api.ns3.${process.env.REACT_APP_STAGE === 'production' ? 'no' : 'dev'}`,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        fullRequestPath: window.location.href,
    },
    validateStatus: (status) => {
        return status >= 200 && status <= 401; // default
    },
});

export const ninitoAuthAxios = axios.create({
    baseURL: `https://auth.api.ninito.${process.env.REACT_APP_STAGE === 'production' ? 'no' : 'dev'}`,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        fullRequestPath: window.location.href,
    },
});

export const coreAxios = axios.create({
    baseURL: `https://core.api.ns3.${process.env.REACT_APP_STAGE === 'production' ? 'no' : 'dev'}`,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        fullRequestPath: window.location.href,
    },
});
