export const parseJwt = (string) => JSON.parse(Buffer.from(string.split('.')[1], 'base64').toString('binary'));

export const getPropInObj = (object, path) => {
    let value = object;

    path.forEach((key) => {
        if (value && value[key] !== undefined) {
            value = value[key];
        } else {
            value = undefined;
        }
    });

    return value;
};
