import React from 'react';
import { Link, Outlet, useMatch } from 'react-router-dom';
import Paragraph from '../../components/Paragraph/Paragraph';
import { getPropInObj } from '../../helperFunctions/parse';
import { StyledDiv } from './SignUpStyled';

const SignUp = () => {
    const matchData = useMatch('/login/signUp/:type');

    return (
        <StyledDiv className="SignUpWrapper">
            <Paragraph fontSize={3} fontWeight={700}>
                Sign up
            </Paragraph>
            <StyledDiv className="SignUpOptionsWrapper">
                <Link to="default">
                    <StyledDiv className={`SignUpOption ${getPropInObj(matchData, ['params', 'type']) === 'default' ? 'active' : ''}`}>
                        <Paragraph color="onWhite" fontSize={1.5} fontWeight={700}>
                            Default
                        </Paragraph>
                    </StyledDiv>
                </Link>
                <Link to="token">
                    <StyledDiv className={`SignUpOption ${getPropInObj(matchData, ['params', 'type']) === 'token' ? 'active' : ''}`}>
                        <Paragraph color="onWhite" fontSize={1.5} fontWeight={700}>
                            Token
                        </Paragraph>
                    </StyledDiv>
                </Link>
            </StyledDiv>
            <Outlet />
        </StyledDiv>
    );
};

export default SignUp;
