import styled from 'styled-components';

const StyledDiv = styled.div`
    &.KeyboardWrapper {
        position: relative;
        min-width: 35rem;
    }
    &.KeyboardBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 2rem);
        background-color: ${(props) => props.theme.darkPeach};
        border-radius: 2rem;
    }
    &.KeyboardContent {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: auto auto;
        grid-gap: 0.5rem;
        padding: 1.5rem 1.5rem 0;
    }
    &.KeyboardInputValueWrapper {
        max-height: 15rem;
        overflow: auto;

        -ms-oveflow-style: none /*IE and Edge*/;
        scrollbar-width: none /*Firefox*/;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }
    &.KeyboardInputField {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: auto min-content min-content;
        grid-gap: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        background-color: ${(props) => props.theme.white};
    }
    &.CharactersLeftWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        opacity: 0.6;
    }
    &.BackspaceWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 200ms ease-in-out;

        svg {
            width: 2.5rem;
            height: 2.5rem;
            color: ${(props) => props.theme.onWhite};
        }

        &:active {
            transform: scale(0.9);
        }
    }

    &.KeyboardKeys {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 2px;
    }

    &.KeyboardRow {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 2px;

        &.space {
            width: 50%;
            margin: auto;
            overflow: hidden;
            border-radius: 0 0 1rem 1rem;
        }
        &.hasSpace {
            &:nth-last-child(2) {
                .KeyboardKey:first-child {
                    border-bottom-left-radius: 1rem;
                }
                .KeyboardKey:last-child {
                    border-bottom-right-radius: 1rem;
                }
            }
        }
        &:first-child {
            .KeyboardKey:first-child {
                border-radius: 1rem 0 0 0;
            }
            .KeyboardKey:last-child {
                border-radius: 0 1rem 0 0;
            }
        }
        &:last-child {
            .KeyboardKey:first-child {
                border-bottom-left-radius: 1rem;
            }
            .KeyboardKey:last-child {
                border-bottom-right-radius: 1rem;
            }
        }
    }

    &.KeyboardKey {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.onWhite};
        font-size: 1.5rem;
        background-color: ${(props) => props.theme.white};
        height: 4.5rem;

        &.enter {
            background-color: ${(props) => props.theme.green};
            color: ${(props) => props.theme.onGreen};
            min-width: 5rem;
        }

        &:active {
            background-color: ${(props) => props.theme.gray};
        }
        &:disabled {
            background-color: ${(props) => props.theme[props.color]};
        }
    }
`;

const StyledTable = styled.table`
    &.KeyboardKeys {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        tr {
            border-spacing: 0.25rem;

            td {
                text-align: center;
                padding: 1rem 1.5rem;
                font-weight: bold;
                color: ${(props) => props.theme.onWhite};
                font-size: 1.5rem;
                background-color: ${(props) => props.theme.white};

                &.roundBottomLeft {
                    border-radius: 0 0 0 1rem;
                }
                &.roundBottomRight {
                    border-radius: 0 0 1rem 0;
                }
                &.roundBottom {
                    border-radius: 0 0 1rem 1rem;
                }
                &.empty {
                    background-color: transparent;
                }
            }
        }
    }
`;

export { StyledDiv, StyledTable };
