import React, { useContext } from 'react';
import { PopupContext } from '../../constants/contexts';
import ModalWrapper from '../../hoc/ModalWrapper';
import Backdrop from '../Backdrop/Backdrop';
import Button from '../Button/Button';
import Paragraph from '../Paragraph/Paragraph';

import { StyledDiv, Wrapper } from './PopupStyled';

const Popup = () => {
    const { popup, setPopup } = useContext(PopupContext);

    const defaultCancel = () => setPopup((e) => ({ ...e, show: false }));

    return (
        <>
            <StyledDiv className="PopupPosition">
                <ModalWrapper show={popup.show}>
                    <Wrapper className="PopupWrapper">
                        {popup.title && (
                            <Paragraph fontWeight={700} fontSize={3} className="center" textAlign="center" color="onWhite">
                                {popup.title}
                            </Paragraph>
                        )}
                        {popup.description && (
                            <Paragraph color="onWhite" fontSize={2.5} fontWeight={500} textAlign="center" className="overflowAnywhere ">
                                {popup.description}
                            </Paragraph>
                        )}
                        <StyledDiv className="Controls">
                            {popup.onConfirm && (
                                <Button color="darkPeach" className="primary huge bold" onClick={popup.onConfirm}>
                                    {popup.confirmText || 'Confirm'}
                                </Button>
                            )}
                            <Button color="onWhite" fontSize={1.75} onClick={popup.onCancel || defaultCancel}>
                                {popup.cancelText || 'Cancel'}
                            </Button>
                        </StyledDiv>
                    </Wrapper>
                </ModalWrapper>
            </StyledDiv>
            <Backdrop onClick={popup.onModalClick || popup.onCancel || defaultCancel} show={popup.show} zIndex={19} />
        </>
    );
};

export default Popup;
