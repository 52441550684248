export const nameLayout = {
    default: ['q w e r t y u i o p å', 'a s d f g h j k l ø æ', 'Shift z x c v b n m enter', 'space'],
    shift: ['Q W E R T Y U I O P Å', 'A S D F G H J K L Ø Æ', 'Shift Z X C V B N M enter', 'space'],
};

export const nameLayoutUpperCase = {
    default: ['Q W E R T Y U I O P Å', 'A S D F G H J K L Ø Æ', 'Z X C V B N M enter', 'space'],
    shift: ['Q W E R T Y U I O P Å', 'A S D F G H J K L Ø Æ', 'Z X C V B N M enter', 'space'],
};

export const defaultKeyboardLayout = {
    default: [
        '` 1 2 3 4 5 6 7 8 9 0 - =',
        'q w e r t y u i o p [ ] \\',
        "a s d f g h j k l ; '",
        'Shift z x c v b n m , . / enter',
        '.com @ space',
    ],
    shift: [
        '~ ! @ # $ % ^ & * ( ) _ +',
        'Q W E R T Y U I O P { } |',
        'A S D F G H J K L : "',
        'Shift Z X C V B N M < > ? enter',
        '.com @ space',
    ],
};

export const emailLayout = {
    default: [
        '1 2 3 4 5 6 7 8 9 0',
        'q w e r t y u i o p å',
        'a s d f g h j k l ø æ',
        'Shift z x c v b n m . ? @ enter',
        '@gmail.com @hotmail.com',
    ],
    shift: [
        "! # $ % & ' * + - / = ^ _ ` { | } ~",
        'Q W E R T Y U I O P Å',
        'A S D F G H J K L Ø Æ',
        'Shift Z X C V B N M . ? @ enter',
        '@gmail.com @hotmail.com',
    ],
};

export const numPadLayout = {
    default: ['1 2 3', '4 5 6', '7 8 9', '0'],
};
