import styled from 'styled-components';

import { resetButton } from '../../constants/mixins';
import { checkForTextColorInvert } from '../../helperFunctions/colors';

const StyledDiv = styled.div`
    &.NumPadWrapper {
        background-color: ${(props) => props.theme.darkPeach};
        padding: 0 2rem;
        border-radius: 2rem;
        margin-bottom: 2rem;
        min-width: 25rem;
    }
    &.InnerTransformWrapper {
        transform: translateY(2rem);
        display: grid;
        grid-template-rows: min-content auto;
        grid-gap: 1rem;
    }
    &.NumPadInputWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1rem;
        border-radius: 1rem;
        background-color: ${(props) => props.theme.white};
        min-height: 2rem;
        border: 0.2rem solid transparent;

        &.error {
            border: 0.2rem solid ${(props) => props.theme.red};
        }

        p {
            word-break: break-all;
        }
    }
    &.NumpadInputLeft {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 0.5rem;

        & > svg {
            width: 3rem;
            height: 3rem;
            color: ${(props) => props.theme.onWhite};
        }

        &.code {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: stretch;
            flex-direction: row;
            grid-gap: 0;

            p {
                text-align: center;
                width: 100%;
            }
        }
    }
    &.Dialer {
        display: grid;
        grid-gap: 0.15rem;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        border-radius: 1rem;
        overflow: hidden;
    }
`;

const StyledButton = styled.div`
    &.BackspaceButton {
        ${resetButton}
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: flex-end;
        background: transparent;
        height: 2rem;
        width: 3rem;
        padding: 0;
        transition: all 100ms ease-in-out;

        svg {
            height: 3rem;
            width: 3rem;
            color: ${(props) => props.theme.onWhite};
        }

        &:active {
            transform: scale(0.9);
        }
    }
    &.DialerButton {
        ${resetButton}
        gap: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${(props) => props.theme[props.color]};
        color: ${(props) => props.theme[checkForTextColorInvert(props.color)]};
        font-size: 1.5rem;
        font-weight: bold;
        min-width: 8rem;
        min-height: 4rem;
        height: 5.25rem;

        & > span {
            color: ${(props) => props.theme[checkForTextColorInvert(props.color)]};
        }

        &:active {
            background-color: ${(props) => props.theme.gray};
        }
        &:disabled {
            background-color: ${(props) => props.theme[props.color]};
        }
    }
`;

const StyledSpan = styled.span`
    &.DialerButtonAlphabet {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 0.25rem;
    }
    &.DialerButtonNumber {
        font-size: 1.5rem;
        font-weight: bold;
    }
`;

const StyledImage = styled.img`
    &.NumPadFlag {
        height: 1.5rem;
    }
`;

export { StyledDiv, StyledButton, StyledSpan, StyledImage };
