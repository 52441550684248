import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

let firebaseConfig = null;

if (process.env.REACT_APP_STAGE === 'production') {
    // Used for production
    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_BASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
} else {
    // Used for local and dev
    firebaseConfig = {
        apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_BASE_URL,
        projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
    };
}

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
