import styled from 'styled-components';
import { hexToRgbA } from '../../helperFunctions/colors';

const Wrapper = styled.div`
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    z-index: 999;
    background-color: ${(props) => hexToRgbA(props.theme.orange, 0.85)};
    padding: 0.75rem;
    border-radius: 0.5rem;
    max-width: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.appear-enter {
        opacity: 0;
    }
    &.appear-enter-active {
        opacity: 0.7;
        transition: opacity 300ms;
    }
    &.appear-exit {
        opacity: 0.7;
    }
    &.appear-exit-active {
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }

    @keyframes grow {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.05);
        }
    }

    svg {
        margin: auto;
        margin-bottom: 0.25rem;
        height: 2.5rem;
        color: ${(props) => props.theme.white};
        animation: grow 2s linear 2s infinite alternate;
    }
`;

export { Wrapper };
