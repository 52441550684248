import styled from 'styled-components';
import { defaultBoxShadow } from '../../constants/mixins';

const StyledDiv = styled.div`
    &.MainWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.peach};
        flex-direction: column;
        min-height: 100vh;
    }
    &.ContentIconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;

        @media (max-width: 800px) {
            padding: 0.5rem 0;
        }

        & > svg {
            width: 10rem;
            height: 10rem;
        }
    }
    &.Content {
        background-color: ${(props) => props.theme.white};
        width: 40rem;
        margin: auto;
        border-radius: 2rem;
        padding: 3rem;
        max-width: 95vw;
        box-sizing: border-box;
        margin: 1rem;
        ${defaultBoxShadow}

        @media (max-width: 800px) {
            padding: 2rem 0.75rem 2rem;
            max-width: calc(100% - 1rem);
            border-radius: 1rem;
            margin: 0.5rem;
        }
    }
`;

const TOSLink = styled.a`
    color: ${(props) => props.theme.onWhite};
    font-weight: 700;
    margin-bottom: 1rem;
`;

export { StyledDiv, TOSLink };
