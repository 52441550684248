import styled from 'styled-components';
import { defaultBoxShadow } from '../../constants/mixins';
import { checkForTextColorInvert } from '../../helperFunctions/colors';

const StyledDiv = styled.div`
    &.CloseButton {
        cursor: pointer;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: ${(props) => `${props.size}rem`};
        height: ${(props) => `${props.size}rem`};
        background-color: ${(props) => props.theme[props.color]};
        ${defaultBoxShadow}
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        svg {
            width: ${(props) => `${props.iconSize}rem`};
            height: ${(props) => `${props.iconSize}rem`};
            color: ${(props) => props.theme[checkForTextColorInvert(props.color)]};
        }
    }
`;

export { StyledDiv };
