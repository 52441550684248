import styled from 'styled-components';

const StyledDiv = styled.div`
    &.QrCodeSignInWrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
    &.QrCodeSignInContent {
        width: 70rem;
        max-width: 95vw;
        max-height: 95vh;
        background-color: ${(props) => props.theme.peach};
        box-sizing: border-box;
        border-radius: 2rem;
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1.5rem;
        overflow: auto;
    }
    &.QrCodeSignInColumns {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.5rem;
        padding: 0 3rem 3rem;
        overflow: auto;
    }
    &.QrCodeWrapper {
        height: 15rem;
        margin: auto;
        padding-bottom: 2rem;
    }
    &.QrCodeSignInInstructionsWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
        min-width: 20rem;
    }
    &.QrCodeSignInNumpadWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export { StyledDiv };
