import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Backspace } from '@styled-icons/ionicons-solid';

import Paragraph from '../Paragraph/Paragraph';
import { StyledDiv } from './KeyboardStyled';
import { emailLayout } from '../../constants/keyboard';
import Loading from '../Loading/Loading';
import KeyboardIndicator from '../KeyboardIndicator/KeyboardIndicator';

const KeyboardKeys = ({ onClick, layoutName, layout, onEnter, loading }) => {
    if (!layout || !layoutName || !layout[layoutName]) return null;

    return (
        <StyledDiv className="KeyboardKeys">
            {layout[layoutName].map((row) => {
                const keys = row.split(' ');

                return (
                    <StyledDiv
                        key={row}
                        className={`KeyboardRow ${emailLayout.default.findIndex((e) => e === '{space}') !== -1 ? 'hasSpace' : ''} ${
                            row === '{space}' ? 'space' : ''
                        }`}
                    >
                        {keys.map((key) => {
                            const onKeyClick = () => {
                                if (key === 'enter') {
                                    onEnter();
                                } else {
                                    onClick(key);
                                }
                            };

                            let keyText = null;

                            if (key === 'enter') {
                                keyText = loading ? <Loading color="onGreen" /> : 'Enter';
                            } else {
                                keyText = key;
                            }

                            return (
                                <StyledDiv onClick={onKeyClick} key={key} className={`KeyboardKey ${key}`}>
                                    {keyText}
                                </StyledDiv>
                            );
                        })}
                    </StyledDiv>
                );
            })}
        </StyledDiv>
    );
};

KeyboardKeys.defaultProps = {
    onEnter: null,
    loading: false,
};

KeyboardKeys.propTypes = {
    onClick: PropTypes.func.isRequired,
    layoutName: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    loading: PropTypes.bool,
    layout: PropTypes.shape({ default: PropTypes.arrayOf(PropTypes.string), shift: PropTypes.arrayOf(PropTypes.string) }).isRequired,
};

const Keyboard = ({ value, onChange, max, layoutName, onEnter, loading, layout, passwordProtected }) => {
    const [showValue, setShowValue] = useState('');
    const prevValue = useRef(value.length);
    const inputValueRef = useRef();

    useEffect(() => {
        let timeout = null;

        if (passwordProtected) {
            if (value.length > prevValue.current) {
                setShowValue(value.replace(/\w(?!\w{0,0}$)/g, '*'));

                timeout = setTimeout(() => {
                    setShowValue(value.replace(/\w/g, '*'));
                }, 500);
            } else {
                setShowValue(value.replace(/\w/g, '*'));
            }
        } else {
            setShowValue(value);
        }

        prevValue.current = value.length;

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [passwordProtected, value]);

    useEffect(() => {
        if (inputValueRef && inputValueRef.current) {
            inputValueRef.current.scrollTop = inputValueRef.current.scrollHeight;
        }
    }, [value]);

    return (
        <StyledDiv className="KeyboardWrapper">
            <StyledDiv className="KeyboardContent">
                <StyledDiv className="KeyboardInputField">
                    <StyledDiv ref={inputValueRef} className="KeyboardInputValueWrapper">
                        <Paragraph color="onWhite" lineHeight={2.25} fontWeight={700} className="overflowAnywhere" fontSize={2}>
                            <span>{showValue}</span>
                            <KeyboardIndicator fontSize={2.25} color="onWhite" />
                        </Paragraph>
                    </StyledDiv>
                    {max && (
                        <StyledDiv className="CharactersLeftWrapper">
                            <Paragraph fontWeight={700} className="right">
                                {value.length}/{max}
                            </Paragraph>
                            <Paragraph fontWeight={700} className="noWrap">
                                Characters left
                            </Paragraph>
                        </StyledDiv>
                    )}
                    <StyledDiv onClick={() => onChange('⟵')} className="BackspaceWrapper">
                        <Backspace />
                    </StyledDiv>
                </StyledDiv>
                <KeyboardKeys layout={layout} loading={loading} onEnter={onEnter} layoutName={layoutName} onClick={onChange} />
            </StyledDiv>
            <StyledDiv className="KeyboardBackground" />
        </StyledDiv>
    );
};

Keyboard.defaultProps = {
    max: null,
    onEnter: null,
    loading: false,
    layoutName: 'default',
    passwordProtected: false,
};

Keyboard.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    max: PropTypes.number,
    layoutName: PropTypes.string,
    onEnter: PropTypes.func,
    loading: PropTypes.bool,
    layout: PropTypes.shape({ default: PropTypes.arrayOf(PropTypes.string), shift: PropTypes.arrayOf(PropTypes.string) }).isRequired,
    passwordProtected: PropTypes.bool,
};

export default Keyboard;
