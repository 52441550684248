import React from 'react';
import { Outlet } from 'react-router-dom';

import { ReactComponent as NinitoSVG } from '../../assets/ninito-black.svg';
import Paragraph from '../../components/Paragraph/Paragraph';
import { StyledDiv } from './MainStyled';

const Main = () => {
    return (
        <StyledDiv className="MainWrapper">
            <StyledDiv className="ContentIconWrapper">
                <NinitoSVG />
            </StyledDiv>
            <StyledDiv className="Content">
                <Outlet />
            </StyledDiv>
            <Paragraph marginBottom={1} fontWeight={700} fontSize={1.1} textAlign="center">
                Powered by 992.no
            </Paragraph>
            {/* <TOSLink href="https://992.no/tos" target="_blank" rel="noopener noreferrer">
                Terms of Service
            </TOSLink> */}
        </StyledDiv>
    );
};

export default Main;
