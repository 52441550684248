import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SpinnerSVG } from '../../assets/spinner.svg';

import { Wrapper } from './LoadingStyled';

const Loading = ({ color, fullScreen, className, size }) => {
    return (
        <Wrapper size={size} className={`${className} ${fullScreen ? 'fullScreen' : ''}`} color={color}>
            <SpinnerSVG />
        </Wrapper>
    );
};

Loading.defaultProps = {
    color: 'darkBrown',
    fullScreen: false,
    className: '',
    size: 1.5,
};

Loading.propTypes = {
    color: PropTypes.string,
    fullScreen: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.number,
};

export default Loading;
