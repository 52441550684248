import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import Paragraph from '../../components/Paragraph/Paragraph';
import { authAxios } from '../../constants/axios';

import { StyledDiv } from './PinCodeStyled';
import { AuthContext, PopupContext } from '../../constants/contexts';

const PinCode = () => {
    const { setPopup } = useContext(PopupContext);
    const { authData, setAuthData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { search } = useLocation();
    const [users, setUsers] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState(null);

    const defaultQrCodeId = useMemo(() => {
        const QRCodeId = new URLSearchParams(search).get('QRCodeId');

        return QRCodeId;
    }, [search]);

    const checkQrCodeId = useCallback(
        async ({ QRCodeId, email = null }) => {
            const handleError = (message) => {
                setError(message);
                setPin(null);
                setUsers([]);
                setAuthData((e) => ({ ...e, redirectToPin: false }));
            };

            try {
                setSelectedEmail(email);
                setLoading(true);

                const { data } = await authAxios.post('/generateSignInPin', {
                    QRCodeId,
                    email,
                });

                if (data.success) {
                    if (data.userList) {
                        setUsers(data.userList);
                    }
                    if (data.pinCode) {
                        setPin(data.pinCode);
                    }
                    setError(null);
                    setAuthData((e) => ({ ...e, redirectToPin: false }));
                } else {
                    handleError(data.message);
                }
            } catch (err) {
                handleError(err.response.data.message || err.message);
            } finally {
                setLoading(false);
            }
        },
        [setAuthData]
    );

    const tryAgain = () => {
        if (defaultQrCodeId) {
            checkQrCodeId({ QRCodeId: defaultQrCodeId });
        } else {
            setError('Error, please scan the qr code again.');
        }
    };

    useEffect(() => {
        if (authData && authData.user !== null && !loading && !pin && !error && users.length === 0) {
            if (defaultQrCodeId && (!authData.user || authData.claims.userType !== 'CM')) {
                setAuthData((e) => ({ ...e, redirectToPin: true, QRCodeId: defaultQrCodeId }));
                setPopup({
                    show: true,
                    title: 'Company manager',
                    description: 'Please sign in to your company manager account to access pin code.',
                });
            }

            if (defaultQrCodeId && authData.user && authData.claims.userType === 'CM') {
                checkQrCodeId({ QRCodeId: defaultQrCodeId });
            } else {
                navigate('/login/signIn');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData, error, loading, pin, users.length, defaultQrCodeId]);

    let content = <Loading size={3} />;

    if (error) {
        content = (
            <StyledDiv className="ErrorWrapper">
                <Paragraph fontWeight={700} textAlign="center" marginBottom={1.5} fontSize={2.5} color="onPeachAlternative">
                    {error}
                </Paragraph>
                <Button fontWeight={700} loading={loading} onClick={tryAgain}>
                    Try again
                </Button>
                <Button fontWeight={700} onClick={() => navigate('/login/signIn')}>
                    Back to sign in page
                </Button>
            </StyledDiv>
        );
    }

    if (users && users.length > 0) {
        content = (
            <StyledDiv className="UsersWrapper">
                <Paragraph fontWeight={700} fontSize={2} marginBottom={1} textAlign="center">
                    Select user you want to sign in as
                </Paragraph>
                {users.map((user) => {
                    return (
                        <StyledDiv onClick={() => checkQrCodeId({ QRCodeId: defaultQrCodeId, email: user })} className="User" key={user}>
                            <Paragraph fontWeight={700} textAlign="center">
                                {user}
                            </Paragraph>
                        </StyledDiv>
                    );
                })}
            </StyledDiv>
        );
    }

    if (pin) {
        content = (
            <StyledDiv className="PinCodeWrapper">
                <Paragraph fontSize={2.5} fontWeight={700} textAlign="center">
                    You pin code is
                </Paragraph>
                <Paragraph marginTop={1} fontSize={1.25} fontWeight={700} textAlign="center">
                    {selectedEmail}
                </Paragraph>
                <Paragraph marginVertical={2} fontSize={4.5} fontWeight={700} textAlign="center" color="onPeachAlternative">
                    {pin}
                </Paragraph>
                {users && users.length > 0 && (
                    <Button fontWeight={700} onClick={() => setPin(null)}>
                        Change account
                    </Button>
                )}
            </StyledDiv>
        );
    }

    if (loading) {
        content = <Loading size={3} />;
    }

    return <StyledDiv className="PageWrapper">{content}</StyledDiv>;
};

export default PinCode;
