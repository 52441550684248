import { css } from 'styled-components';

const resetButton = css`
    color: ${(props) => props.theme[props.color]};
    position: relative;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    white-space: nowrap;
`;

const defaultBoxShadow = css`
    box-shadow: 0 0 10px -2px ${(props) => props.theme.onWhiteGray};
`;

const bottomBoxShadow = css`
    box-shadow: 0px 11px 12px -3px rgba(0, 0, 0, 0.25);
`;

const keyboardStyling = css`
    &.KeyboardWrapper {
        .customKeyboardTheme {
            background-color: transparent;
        }
        .customKeyboardTheme .hg-button {
            background-color: ${(props) => props.theme.lightBrown};
            color: ${(props) => props.theme.onLightBrown};
            height: 4.5rem;

            &:active {
                opacity: 0.5;
            }

            span {
                font-size: 1.75rem;
            }
        }
    }
`;

const defaultRoundedButton = css`
    ${resetButton}
    padding: 1rem 1.5rem;
    border-radius: 5rem;
    background-color: ${(props) => props.theme.darkPeach};
    font-weight: 700;
    font-size: 1.25rem;
    color: ${(props) => props.theme.onDarkPeach};
`;

export { resetButton, defaultRoundedButton, bottomBoxShadow, defaultBoxShadow, keyboardStyling };
