import styled from 'styled-components';

import { resetButton } from '../../constants/mixins';

const StyledDiv = styled.div`
    &.ModalHeaderWrapper {
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        align-items: center;

        &.withBorder {
            border-bottom: 1px solid ${(props) => props.theme[props.borderColor]};
        }
    }
    &.ModalHeaderRightContent {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        align-items: center;
        justify-content: flex-end;
    }
`;

const StyledButton = styled.div`
    &.ModalHeaderBackButton {
        ${resetButton}
        background-color: ${(props) => props.theme.darkPeach};
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            min-width: 1.5rem;
            min-height: 1.5rem;
            color: ${(props) => props.theme.onDarkPeach};
        }
    }
    &.ModalHeaderBackButton {
        background-color: ${(props) => props.theme.gray};

        svg {
            color: ${(props) => props.theme.onGray};
        }

        &.custom svg {
            width: 2.5rem;
            height: 2.5rem;
        }

        &.iconFlip svg {
            transform: rotate(180deg);
        }
    }
`;

const StyledH1 = styled.h1`
    text-align: center;
    color: ${(props) => props.theme[props.textColor]};
    font-size: 1.7rem;
    margin: 0 1rem;
    white-space: nowrap;
`;

export { StyledDiv, StyledButton, StyledH1 };
