export const theme = {
    main_font: 'Quicksand',
    font_style: 'sans-serif',

    peach: '#ffefe2',
    onPeach: '#582800',
    onPeachAlternative: '#f54336',
    onPeachBrown: '#cbb49d',
    onPeachDarkBlue: '#011924',

    lightPeach: '#fde3cd',
    onLightPeach: '#582800',

    darkPeach: '#ffc595',
    onDarkPeach: '#582800',

    darkBrown: '#582800',
    onDarkBrown: '#ffffff',

    green: '#36a200',
    onGreen: '#ffffff',

    lightGreen: '#e2ecb0',
    onLightGreenGreen: '#82b01c',

    red: '#f54336',
    onRed: '#ffffff',

    lightRed: '#ffd0d0',
    onLightRedRed: '#ef3131',

    blue: '#9cf1ff ',
    onBlue: '#287796',

    lightBlue: '#dbeef5',
    onLightBlueBlue: '#287796',

    darkBlue: '#287796',
    onDarkBlue: '#ffffff',

    orange: '#eb7300',
    onOrange: '#ffffff',

    yellow: '#ffec9c',
    onYellow: '#ffffff',
    onYellowAlternative: '#f54336',

    white: '#ffffff',
    onWhite: '#582800',
    onWhiteAlternative: '#f54336',
    onWhiteBlue: '#287796',
    onWhiteDarkBlue: '#2c3e50',
    onWhiteBrown: '#cbb49d',
    onWhiteGray: '#bfbfbf',

    light: '#f2f2f2',
    onLight: '#582800',
    onLightAlternative: '#f54336',

    modalBackground: '#ae9b8b',

    lightBrown: '#cbb49d',
    onLightBrown: '#ffffff',

    darkGray: '#bfbfbf',
    gray: '#e7e1db',
    onGray: '#2C2722',
    onGrayAlternative: '#f54336',
};
