import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CheckingForUserModal from './components/CheckingForUserModal/CheckingForUserModal';
import Popup from './components/Popup/Popup';
import { AuthContext, PopupContext } from './constants/contexts';
import { signIn } from './helperFunctions/signIn';
import Main from './hoc/Main/Main';
import { useCheckUser } from './hooks/useCheckUser';
import PinCode from './routes/PinCode/PinCode';
import SignIn from './routes/SignIn/SignIn';
import DefaultSignUp from './routes/SignUp/DefaultSignUp';
import SignUp from './routes/SignUp/SignUp';
import TokenSignUp from './routes/SignUp/TokenSignUp';
import { register } from './serviceWorker';

const CheckAndRedirect = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    useCheckUser();

    useEffect(() => {
        const reloadPath = new URLSearchParams(search).get('reloadPath');
        const nS3Token = new URLSearchParams(search).get('nS3Token');
        const QRCodeId = new URLSearchParams(search).get('QRCodeId');

        if (QRCodeId) {
            navigate(`/login/pin?QRCodeId=${QRCodeId}`);
        } else if (nS3Token) {
            signIn({ token: nS3Token });

            if (reloadPath) {
                navigate(decodeURIComponent(reloadPath));
            } else {
                navigate('/login');
            }
        } else if (reloadPath) {
            navigate(decodeURIComponent(reloadPath));
        } else if (process.env.REACT_APP_STAGE !== 'local') {
            navigate('/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

const popupDefaultState = {
    show: false,
    title: '',
    description: '',
    onConfirm: null,
    confirmText: null,
    cancelText: null,
    onModalClick: null,
};

export default () => {
    const [authData, setAuthData] = useState({ redirectToPin: false, user: null, checkedForUser: false });
    const [popup, setPopup] = useState(popupDefaultState);

    return (
        <AuthContext.Provider value={{ authData, setAuthData }}>
            <PopupContext.Provider value={{ popup, setPopup }}>
                <Routes>
                    <Route path="/login" element={<Main />}>
                        <Route path="signUp" element={<SignUp />}>
                            <Route path="default" element={<DefaultSignUp />} />
                            <Route path="token" element={<TokenSignUp />} />
                            <Route index element={<Navigate replace to="default" />} />
                        </Route>
                        <Route path="signIn" element={<SignIn />} />
                        <Route path="pin" element={<PinCode />} />
                        <Route index element={<Navigate replace to="signIn" />} />
                    </Route>
                    <Route index element={<Navigate replace to="login" />} />
                </Routes>
                <CheckAndRedirect />
                <Popup />
                <CheckingForUserModal show={!authData.checkedForUser} />
            </PopupContext.Provider>
        </AuthContext.Provider>
    );
};

register();
