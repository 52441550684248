import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { normalize } from 'styled-normalize';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { theme } from './constants/theme';

// import fonts
import './assets/fonts/Quicksand/Quicksand-Bold.ttf';
import './assets/fonts/Quicksand/Quicksand-Light.ttf';
import './assets/fonts/Quicksand/Quicksand-Medium.ttf';
import './assets/fonts/Quicksand/Quicksand-Regular.ttf';
import './assets/fonts/Quicksand/Quicksand-SemiBold.ttf';

import './index.css';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    html, body {
        font-size: 100%;
        font-family: 'Quicksand', sans-serif;
       
    }
    
    @media (min-width: 2159px) {
        html {
	        font-size: 120%;
        }
    }

    @media (max-width: 1400px) {
        html {
	        font-size: 16px;
        }
    }


    @media (max-width: 1200px) {
        html {
	        font-size: 14px;
        }
    }
`;

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

root.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
        </ThemeProvider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
