import React from 'react';
import PropTypes from 'prop-types';

import { StyledSpan } from './KeyboardIndicatorStyled';

const KeyboardIndicator = ({ color, fontSize, spaceCount }) => {
    return (
        <StyledSpan spaceCount={spaceCount} className="KeyboardIndicator" fontSize={fontSize} color={color}>
            |
        </StyledSpan>
    );
};

KeyboardIndicator.defaultProps = {
    color: 'onPeach',
    fontSize: 2.5,
    spaceCount: 0,
};

KeyboardIndicator.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.number,
    spaceCount: PropTypes.number,
};

export default KeyboardIndicator;
