/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from '@styled-icons/ionicons-solid';

import { StyledDiv, StyledSelect, StyledLabel } from './SelectStyled';
import Paragraph from '../Paragraph/Paragraph';

const Select = ({ icon, value, onChange, error, type, onFocus, onBlur, name, options, ...rest }) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
        setFocused(true);
        onFocus(e);
    };

    const handleBlur = (e) => {
        setFocused(false);
        onBlur(e);
    };

    return (
        <StyledDiv>
            <StyledLabel focused={focused} className="SelectWrapper">
                {icon && <StyledDiv className="IconWrapper">{icon}</StyledDiv>}
                <StyledSelect {...rest} onFocus={handleFocus} onBlur={handleBlur} name={name} value={value} onChange={onChange}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </StyledSelect>
                <StyledDiv className="ChevronDownWrapper">
                    <ChevronDown />
                </StyledDiv>
            </StyledLabel>
            {error && (
                <StyledDiv>
                    <Paragraph color="red" marginTop={0.4}>
                        {error}
                    </Paragraph>
                </StyledDiv>
            )}
        </StyledDiv>
    );
};

Select.defaultProps = {
    icon: null,
    type: 'text',
    name: '',
    error: null,
    options: [],
    onFocus: () => {},
    onBlur: () => {},
};

Select.propTypes = {
    icon: PropTypes.element,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
    ),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

export default Select;
