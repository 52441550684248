import styled from 'styled-components';

const StyledDiv = styled.div`
    &.KeyboardModalWrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
    &.KeyboardModalContent {
        width: 65rem;
        max-width: 95vw;
        max-height: 95vh;
        background-color: ${(props) => props.theme.peach};
        box-sizing: border-box;
        border-radius: 2rem;
        display: grid;
        grid-auto-flow: row;
        overflow: auto;
        grid-gap: 0.5rem;
    }
    &.KeyboardWrapper {
        overflow: auto;
        padding: 0 2rem 2rem;
    }
`;

export { StyledDiv };
