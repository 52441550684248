import { useMemo } from 'react';
import * as Yup from 'yup';

export const useYupDefaults = () => {
    const defaults = useMemo(
        () => ({
            email: Yup.string().email().required('Email is required'),
            password: Yup.string().required('Password is required').min(6).max(200),
        }),
        []
    );

    return defaults;
};
