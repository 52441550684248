import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@styled-icons/ionicons-solid';

import { StyledDiv, StyledH1 } from './ModalHeaderStyled';
// use arrow next and then just rotate it
import CloseButton from '../CloseButton/CloseButton';

const ModalHeader = ({ title, onClose, textColor, borderColor, className, onBack, onBackIcon, backIconSize, rightControls }) => {
    return (
        <StyledDiv borderColor={borderColor} className={`ModalHeaderWrapper ${className}`}>
            {onBack ? (
                <CloseButton size={3.5} onClick={onBack} iconSize={backIconSize} icon={onBackIcon || <ArrowBack />} color="gray" />
            ) : (
                <div />
            )}
            <StyledH1 textColor={textColor}>{title}</StyledH1>
            <StyledDiv className="ModalHeaderRightContent">
                {rightControls}
                <CloseButton iconSize={2.5} size={3.5} onClick={onClose} color="darkPeach" />
            </StyledDiv>
        </StyledDiv>
    );
};

ModalHeader.defaultProps = {
    className: '',
    onBack: null,
    onBackIcon: null,
    title: '',
    rightControls: null,
    backIconSize: 2.5,
    textColor: 'onWhite',
    borderColor: 'gray',
};

ModalHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    onBack: PropTypes.func,
    onBackIcon: PropTypes.element,
    backIconSize: PropTypes.number,
    rightControls: PropTypes.element,
    textColor: PropTypes.string,
    borderColor: PropTypes.string,
};

export default ModalHeader;
