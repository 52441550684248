import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const ModalWrapper = ({ show, children, onEnter, onExited }) => {
    return (
        <CSSTransition in={show} onExited={onExited} onEnter={onEnter} timeout={200} classNames="appear" unmountOnExit>
            <AnimatedDiv>{children}</AnimatedDiv>
        </CSSTransition>
    );
};

const AnimatedDiv = styled.div`
    &.appear-enter {
        opacity: 0;
        transform: scale(0.9);
    }
    &.appear-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
        transition-timing-function: ease-out;
    }
    &.appear-exit {
        opacity: 1;
    }
    &.appear-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
        transition-timing-function: ease-out;
    }
`;

ModalWrapper.defaultProps = {
    onEnter: null,
    onExited: null,
};

ModalWrapper.propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};
export default ModalWrapper;
