export const countryList = [
    { label: 'Norway', value: 'no' },
    { label: 'Denmark', value: 'dk' },
    { label: 'Sweden', value: 'se' },
    { label: 'Iceland', value: 'is' },
    { label: 'Finland', value: 'fi' },
    { label: 'Lithuania', value: 'lt' },
    { label: 'Estonia', value: 'est' },
    { label: 'Latvia', value: 'lv' },
    { label: 'Great Britain', value: 'uk' },
    { label: 'Netherlands', value: 'nl' },
    { label: 'Belgium', value: 'be' },
    { label: 'Germany', value: 'de' },
    { label: 'France', value: 'fr' },
    { label: 'Spain', value: 'es' },
    { label: 'Greece', value: 'gr' },
    { label: 'Switzerland', value: 'ch' },
    { label: 'Austria', value: 'at' },
    { label: 'Portugal', value: 'pt' },
];
