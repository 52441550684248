import styled from 'styled-components';
import { defaultBoxShadow } from '../../constants/mixins';

const StyledDiv = styled.div`
    &.SignUpWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 2rem;
    }
    &.InputsWrapper {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
        padding-bottom: 2rem;
    }
    &.SignUpOptionsWrapper {
        display: grid;
        grid-auto-flow: column;
        grid-column: 1fr;
        grid-gap: 1rem;

        a {
            text-decoration: none;
        }
    }
    &.SignUpOption {
        padding: 1.25rem;
        background-color: ${(props) => props.theme.white};
        border-radius: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        ${defaultBoxShadow}

        &:hover, &.active {
            &:after {
                content: '';
                position: absolute;
                top: 0.25rem;
                left: 0.25rem;
                border-radius: 1rem;
                width: calc(100% - 0.5rem - 4px);
                height: calc(100% - 0.5rem - 4px);
                border: 2px solid ${(props) => props.theme.onWhite};
            }
        }
    }
`;

const StyledForm = styled.form`
    &.SignUpForm {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1rem;
    }
`;

export { StyledDiv, StyledForm };
