import styled from 'styled-components';
import { hexToRgbA } from '../../helperFunctions/colors';

const StyledP = styled.p`
    color: ${(props) => hexToRgbA(props.theme[props.color], props.opacity)};
    font-family: ${(props) => props.theme.main_font}, ${(props) => props.theme.font_style};
    font-size: ${(props) => `${props.fontSize}rem`};
    line-height: ${(props) => `${props.lineHeight}rem`};
    font-weight: ${(props) => props.fontWeight};
    text-align: ${(props) => props.textAlign};
    margin-left: ${(props) => `${props.marginLeft}rem`};
    margin-right: ${(props) => `${props.marginRight}rem`};
    margin-top: ${(props) => `${props.marginTop}rem`};
    margin-bottom: ${(props) => `${props.marginBottom}rem`};
    font-weight: ${(props) => props.fontWeight};

    &.noWrap {
        white-space: nowrap;
    }

    &.preWrap {
        white-space: pre-wrap;
    }

    &.overflowAnywhere {
        word-break: break-word;
        overflow-wrap: anywhere;
    }

    &.breakAll {
        word-break: break-all;
    }

    &.underline {
        text-decoration: underline;
    }
`;

export { StyledP };
