/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Eye, EyeOff } from '@styled-icons/ionicons-solid';

import { StyledDiv, StyledInput, StyledLabel } from './InputStyled';
import { ReactComponent as KeyboardSVG } from '../../assets/icons/keyboard.svg';
import KeyboardModal from '../KeyboardModal/KeyboardModal';
import { useKeyboard } from '../../hooks/useKeyboard';
import { emailLayout, defaultKeyboardLayout } from '../../constants/keyboard';
import Paragraph from '../Paragraph/Paragraph';

const Input = ({ icon, value, onChange, error, type, onFocus, onBlur, placeholder, name, ...rest }) => {
    const labelRef = useRef(null);
    const [focused, setFocused] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { handleEmailChange, handleDefaultChange, layoutName } = useKeyboard({ max: 256, value });

    const layout = useMemo(() => {
        switch (type) {
            case 'email':
                return emailLayout;
            default:
                return defaultKeyboardLayout;
        }
    }, [type]);

    const onKeyboardClick = (e) => {
        let textValue = null;

        if (type === 'email') {
            textValue = handleEmailChange(e);
        } else {
            textValue = handleDefaultChange(e);
        }

        onChange({ type, target: { name, value: textValue } });
    };

    const handleFocus = (e) => {
        setFocused(true);
        onFocus(e);
    };

    const handleBlur = (e) => {
        setFocused(false);
        onBlur(e);
    };

    return (
        <>
            <StyledDiv>
                <StyledLabel ref={labelRef} focused={focused} className="InputWrapper">
                    {icon && <StyledDiv className="IconWrapper">{icon}</StyledDiv>}
                    <StyledInput
                        {...rest}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        name={name}
                        placeholder={placeholder}
                        type={showPassword ? 'text' : type}
                        value={value}
                        onChange={onChange}
                    />
                    {type === 'password' && (
                        <StyledDiv className="HideShowPasswordButton" onClick={() => setShowPassword((e) => !e)}>
                            {showPassword ? <Eye /> : <EyeOff />}
                        </StyledDiv>
                    )}
                    <StyledDiv onClick={() => setShowModal(true)} className="KeyboardActionButton">
                        <KeyboardSVG />
                    </StyledDiv>
                </StyledLabel>
                {error && (
                    <StyledDiv>
                        <Paragraph color="red" marginTop={0.4}>
                            {error}
                        </Paragraph>
                    </StyledDiv>
                )}
            </StyledDiv>
            <KeyboardModal
                type={type}
                title={placeholder}
                value={value ? value.toString() : ''}
                onChange={onKeyboardClick}
                show={showModal}
                onClose={() => setShowModal(false)}
                layoutName={layoutName}
                layout={layout}
            />
        </>
    );
};

Input.defaultProps = {
    icon: null,
    type: 'text',
    placeholder: '',
    name: '',
    error: null,
    onFocus: () => {},
    onBlur: () => {},
};

Input.propTypes = {
    icon: PropTypes.element,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

export default Input;
