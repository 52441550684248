import React from 'react';
import PropTypes from 'prop-types';

import { StyledP } from './ParagraphStyled';
import { calculateByPriority } from '../../helperFunctions/ui';

const Paragraph = ({
    children,
    className,
    color,
    fontSize,
    lineHeight,
    onClick,
    opacity,
    margin,
    marginVertical,
    marginHorizontal,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    fontWeight,
    textAlign,
}) => {
    return (
        <StyledP
            onClick={onClick}
            lineHeight={lineHeight !== null ? lineHeight : fontSize}
            fontSize={fontSize}
            color={color}
            opacity={opacity}
            className={className}
            fontWeight={fontWeight}
            textAlign={textAlign}
            marginRight={calculateByPriority([marginRight, marginHorizontal, margin])}
            marginLeft={calculateByPriority([marginLeft, marginHorizontal, margin])}
            marginTop={calculateByPriority([marginTop, marginVertical, margin])}
            marginBottom={calculateByPriority([marginBottom, marginVertical, margin])}
        >
            {children}
        </StyledP>
    );
};

Paragraph.defaultProps = {
    className: '',
    color: 'onWhite',
    fontSize: 1,
    lineHeight: null,
    opacity: 1,
    onClick: undefined,
    margin: 0,
    marginVertical: null,
    marginHorizontal: null,
    marginTop: null,
    marginRight: null,
    marginLeft: null,
    fontWeight: 400,
    marginBottom: null,
    textAlign: 'left',
};

Paragraph.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    onClick: PropTypes.func,
    opacity: PropTypes.number,
    margin: PropTypes.number,
    marginVertical: PropTypes.number,
    marginHorizontal: PropTypes.number,
    marginTop: PropTypes.number,
    marginRight: PropTypes.number,
    marginLeft: PropTypes.number,
    marginBottom: PropTypes.number,
    fontWeight: PropTypes.number,
    textAlign: PropTypes.string,
};

export default Paragraph;
