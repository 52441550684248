import styled from 'styled-components';

const StyledSpan = styled.span`
    @keyframes opacityOn {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    &.KeyboardIndicator {
        font-size: ${(props) => `${props.fontSize}rem`};
        color: ${(props) => props.theme[props.color]};
        animation: opacityOn 4s normal forwards infinite;
        animation-delay: 2s;
        margin-left: ${(props) => `${props.spaceCount * 0.25}rem `};
    }
`;

export { StyledSpan };
