import React, { useContext, useEffect, useState } from 'react';
import { Key } from '@styled-icons/ionicons-solid';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from 'styled-components';

import Backdrop from '../../components/Backdrop/Backdrop';
import NumPad from '../../components/NumPad/NumPad';
import Paragraph from '../../components/Paragraph/Paragraph';
import { authAxios } from '../../constants/axios';
import ModalWrapper from '../../hoc/ModalWrapper';
import { StyledDiv } from './QrCodeSignInStyled';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import { generateId } from '../../helperFunctions/generate';
import { signInToFirebase } from '../../firebase/signInToFirebase';
import { PopupContext } from '../../constants/contexts';

const QrCodeSignIn = ({ show, onClose }) => {
    const { setPopup } = useContext(PopupContext);
    const theme = useTheme();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (show) {
            const id = generateId();

            const url = `https://ns3.${process.env.REACT_APP_STAGE === 'production' ? 'no' : 'dev'}/login/index.html?QRCodeId=${id}`;

            if (process.env.REACT_APP_STAGE === 'local') {
                console.log(`http://localhost:3000/login/index.html?reloadPath=${encodeURIComponent(`/login/pin?QRCodeId=${id}`)}`);
            }

            setQrCode({
                id,
                url,
            });
        }
    }, [show]);

    const handleError = (message) => {
        // alert user with error message
        setPopup({
            show: true,
            title: 'Error',
            description: message,
        });
    };

    const onEnter = async () => {
        if (!qrCode) return;

        // validate code
        setError(code.length !== 4);

        if (code.length !== 4) return;

        setLoading(true);
        try {
            const { data } = await authAxios.post('/signInWithQR', { QRCodeId: qrCode.id, pinCode: code });

            if (data && data.success) {
                await signInToFirebase(data.accessToken);

                if (data.userType) {
                    window.location.href = `/${data.userType}/index.html`;
                } else {
                    window.location.href = '/redirect/index.html';
                }
            } else {
                handleError(data.message);
            }
        } catch (err) {
            handleError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <StyledDiv className="QrCodeSignInWrapper">
                <ModalWrapper show={show}>
                    <StyledDiv className="QrCodeSignInContent">
                        <ModalHeader textColor="onPeach" onClose={onClose} title="Sign in with qr code" />
                        <StyledDiv className="QrCodeSignInColumns">
                            <StyledDiv className="QrCodeSignInInstructionsWrapper">
                                <StyledDiv className="QrCodeWrapper">
                                    {qrCode && qrCode.url && (
                                        <QRCodeSVG
                                            bgColor={theme.peach}
                                            fgColor={theme.onPeachDarkBlue}
                                            height="100%"
                                            width="100%"
                                            value={qrCode.url}
                                        />
                                    )}
                                </StyledDiv>
                                <Paragraph fontSize={2} lineHeight={2.5} color="onPeach" fontWeight={700}>
                                    1. Scan the QR code with your phone camera
                                </Paragraph>
                                <Paragraph fontSize={1.25} lineHeight={2} color="onPeach" fontWeight={700}>
                                    2. Open the link and sign in to your manager account
                                </Paragraph>
                                <Paragraph fontSize={1.25} lineHeight={2} color="onPeach" fontWeight={700}>
                                    3. Enter the pin code which is displayed on your phone
                                </Paragraph>
                                <Paragraph fontSize={1.75} lineHeight={2.5} color="onPeachAlternative" fontWeight={700}>
                                    Login to your kiosk account
                                </Paragraph>
                            </StyledDiv>
                            <StyledDiv className="QrCodeSignInNumpadWrapper">
                                <NumPad
                                    icon={<Key />}
                                    onEnter={onEnter}
                                    type="code"
                                    loading={loading}
                                    onChange={setCode}
                                    value={code}
                                    error={error}
                                />
                            </StyledDiv>
                        </StyledDiv>
                    </StyledDiv>
                </ModalWrapper>
            </StyledDiv>
            <Backdrop show={show} onClick={onClose} />
        </>
    );
};

QrCodeSignIn.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default QrCodeSignIn;
