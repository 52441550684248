import { authAxios } from '../constants/axios';
import { signInToFirebase } from '../firebase/signInToFirebase';

export const signInWithToken = (token) => authAxios.get(`/token?nS3Token=${token}`);

export const signIn = async (body) => {
    let responseData = null;

    if (body.token) {
        const { data } = await signInWithToken(body.token);

        responseData = data;
    } else {
        const { data } = await authAxios.post('/', body);

        responseData = data;
    }

    if (responseData.success) {
        await signInToFirebase(responseData.accessToken);

        return responseData;
    }

    return Promise.reject(responseData.message);
};
