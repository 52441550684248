/* eslint-disable no-unreachable */
import { Formik } from 'formik';
import React, { useContext, useMemo, useState } from 'react';
import { Mail, LockClosed, QrCode } from '@styled-icons/ionicons-solid';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { StyledDiv, StyledForm } from './SignInStyled';
import Button from '../../components/Button/Button';
import { getFormikError } from '../../helperFunctions/formik';
import Input from '../../components/Input/Input';
import { signIn } from '../../helperFunctions/signIn';
import Paragraph from '../../components/Paragraph/Paragraph';
import { useYupDefaults } from '../../hooks/useYupDefaults';
import CloseButton from '../../components/CloseButton/CloseButton';
import QrCodeSignIn from '../QrCodeSignIn/QrCodeSignIn';
import { AuthContext, PopupContext } from '../../constants/contexts';

const SignIn = () => {
    const { authData, setAuthData } = useContext(AuthContext);
    const { setPopup } = useContext(PopupContext);
    const navigate = useNavigate();
    const [qrCodeShow, setQrCodeShow] = useState(false);
    const { email, password } = useYupDefaults();

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                email,
                password,
            }),
        [email, password]
    );

    const handleError = (message) => {
        // alert user with error message
        setPopup({
            show: true,
            title: 'Error',
            description: message,
        });
    };

    const handleSignIn = async (values) => {
        try {
            const body = {
                email: values.email,
                password: values.password,
            };

            setAuthData((e) => ({ ...e, user: null }));

            const data = await signIn(body);

            if (data.success) {
                if (authData.redirectToPin && data.userType === 'CM') {
                    navigate(`/login/pin?QRCodeId=${authData.QRCodeId}`, { replace: true });
                } else if (data.userType) {
                    window.location.href = `/${data.userType}/index.html`;
                } else {
                    window.location.href = '/redirect/index.html';
                }
            } else {
                handleError(data.message);
            }
        } catch (err) {
            // display the error message to the user -> it gives valuable info about the
            handleError(err);
        }
    };

    const toggleQrCodeShow = () => setQrCodeShow((e) => !e);

    return (
        <StyledDiv className="SignInWrapper">
            <StyledDiv className="SignInHeader">
                <Paragraph fontSize={3} fontWeight={700}>
                    Sign in
                </Paragraph>
                <CloseButton onClick={toggleQrCodeShow} withShadow color="onWhite" size={3.5} iconSize={1.75} icon={<QrCode />} />
            </StyledDiv>
            <Formik onSubmit={handleSignIn} validationSchema={validationSchema} initialValues={{ email: '', password: '' }}>
                {({ values, handleChange, handleSubmit, handleBlur, touched, errors, isSubmitting }) => {
                    return (
                        <StyledForm onSubmit={handleSubmit} className="SignInForm">
                            <StyledDiv className="InputsWrapper">
                                <Input
                                    autoComplete="email"
                                    placeholder="Enter your email"
                                    icon={<Mail />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    error={getFormikError({ touched, errors, path: ['email'] })}
                                    name="email"
                                    type="email"
                                />
                                <Input
                                    autoComplete="current-password"
                                    placeholder="Enter your password"
                                    icon={<LockClosed />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    error={getFormikError({ touched, errors, path: ['password'] })}
                                    name="password"
                                    type="password"
                                />
                            </StyledDiv>
                            <Button fontWeight={500} loading={isSubmitting} type="submit">
                                Sign in
                            </Button>
                        </StyledForm>
                    );
                }}
            </Formik>
            <QrCodeSignIn show={qrCodeShow} onClose={toggleQrCodeShow} />
        </StyledDiv>
    );
};

export default SignIn;
