import styled from 'styled-components';

import { resetButton } from '../../constants/mixins';
import { checkForTextColorInvert } from '../../helperFunctions/colors';

const StyledButton = styled.button`
    ${resetButton}
    overflow: hidden;
    transition: opacity 150ms ease-in-out;
    padding-left: ${(props) => `${props.paddingLeft}rem`};
    padding-right: ${(props) => `${props.paddingRight}rem`};
    padding-top: ${(props) => `${props.paddingTop}rem`};
    padding-bottom: ${(props) => `${props.paddingBottom}rem`};
    font-size: ${(props) => `${props.fontSize}rem`};
    opacity: ${(props) => (props.disabled ? 0.2 : 1)};
    font-weight: ${(props) => props.fontWeight};
    border-radius: 1rem;
    background-color: ${(props) => props.theme[props.color]};
    color: ${(props) => props.theme[checkForTextColorInvert(props.color)]};
    outline: none;

    svg {
        width: ${(props) => `${props.fontSize}rem`};
        height: ${(props) => `${props.fontSize}rem`};
        min-width: ${(props) => `${props.fontSize}rem`};
        min-height: ${(props) => `${props.fontSize}rem`};
        max-width: ${(props) => `${props.fontSize}rem`};
        max-height: ${(props) => `${props.fontSize}rem`};
        color: ${(props) => props.theme[checkForTextColorInvert(props.color)]};
    }

    &:focus {
        &:after {
            content: '';
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
            border-radius: 0.75rem;
            width: calc(100% - 0.5rem - 4px);
            height: calc(100% - 0.5rem - 4px);
            border: 2px solid ${(props) => props.theme.onDarkBrown};
        }
    }
`;

const StyledDiv = styled.div`
    &.LoadingWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme[props.color]};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export { StyledButton, StyledDiv };
