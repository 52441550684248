import { useState } from 'react';

export const useKeyboard = ({ max, value, defaultLayoutName = 'default' }) => {
    const [layoutName, setLayoutName] = useState(defaultLayoutName);
    const [isBackSpace, setIsBackspace] = useState(false);

    const handleEmailChange = (e) => {
        if (e === 'Shift') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        } else if (e === '{lock}') {
            setIsBackspace(!isBackSpace);
            setLayoutName(isBackSpace ? 'default' : 'shift');
        } else if (e === '⟵') {
            return value.substring(0, value.length - 1);
        } else if (e === 'space') {
            // we do not allow space
        } else if (!isBackSpace) {
            if (max <= value.length) return value;

            if (e.includes('@') && value.includes('@')) {
                e = e.replace('@', '');
            }

            return value + e;
        }

        return value;
    };

    const handleNameChange = (e) => {
        if (e === 'Shift' || e === 'space') {
            if (e === 'space') {
                if (max <= value.length) return value;

                return `${value} `;
            }
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        } else if (e === '{lock}') {
            setIsBackspace(!isBackSpace);
            setLayoutName(isBackSpace ? 'default' : 'shift');
        } else if (e === '⟵') {
            if (value && value[value.length - 1] === ' ') {
                setLayoutName('default');
            }

            return value.substring(0, value.length - 1);
        } else if (!isBackSpace) {
            if (max <= value.length) return value;
            setLayoutName('default');

            return value + e;
        }
        return value;
    };

    const handleDefaultChange = (e) => {
        if (e === 'space') {
            if (max <= value.length) return value;
            return `${value} `;
        }

        if (e === 'Shift') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        } else if (e === '{lock}') {
            setIsBackspace(!isBackSpace);
            setLayoutName(isBackSpace ? 'default' : 'shift');
        } else if (e === '⟵') {
            return value.substring(0, value.length - 1);
        } else if (!isBackSpace) {
            if (max <= value.length) return value;
            setLayoutName('default');
            return value + e;
        }

        return value;
    };

    return { handleEmailChange, handleDefaultChange, layoutName, isBackSpace, handleNameChange };
};
