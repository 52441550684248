import styled from 'styled-components';
import { defaultBoxShadow } from '../../constants/mixins';

const StyledInput = styled.input`
    padding: 1rem 0;
    border: none;
    font-size: 1.2rem;
    outline: none;
    flex: 1;
    color: ${(props) => props.theme.onWhite};
    min-width: 0;

    &::placeholder {
        color: ${(props) => props.theme.onWhite};
    }
`;

const StyledLabel = styled.label`
    &.InputWrapper {
        padding: 0 1rem;
        border-radius: 1rem;
        background-color: ${(props) => props.theme.white};
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        ${defaultBoxShadow}
        transition: all 50ms ease-in-out;
        border-style: solid;
        border-color: ${(props) => (props.focused ? props.theme.onWhite : props.theme.white)};
        border-width: 2px;

        svg {
            width: 2rem;
            height: 2rem;
            color: ${(props) => props.theme.onWhite};
        }
    }
`;

const StyledDiv = styled.div`
    &.KeyboardActionButton,
    &.HideShowPasswordButton {
        margin: auto 0;
        padding-left: 1rem;

        svg {
            transition: transform 0.2s ease-in-out;
            cursor: pointer;
        }

        &:hover svg {
            transform: scale(1.1);
        }
    }

    &.IconWrapper {
        margin: auto 0;
        padding-right: 2rem;
        position: relative;

        &::after {
            content: '';
            width: 1px;
            height: 100%;
            border-radius: 1rem;
            background-color: ${(props) => props.theme.onWhiteGray};
            position: absolute;
            right: 1rem;
            top: 0;
        }
    }
`;

export { StyledDiv, StyledInput, StyledLabel };
