import React from 'react';
import PropTypes from 'prop-types';

import { StyledDiv } from './KeyboardModalStyled';
import Backdrop from '../Backdrop/Backdrop';
import Keyboard from '../Keyboard/Keyboard';
import ModalWrapper from '../../hoc/ModalWrapper';
import ModalHeader from '../ModalHeader/ModalHeader';

const KeyboardModal = ({ value, onChange, show, onClose, title, layoutName, layout, type }) => {
    const onSubmit = () => {
        onClose();
    };

    return (
        <>
            <StyledDiv className="KeyboardModalWrapper">
                <ModalWrapper show={show}>
                    <StyledDiv className="KeyboardModalContent">
                        <ModalHeader textColor="onPeach" onClose={onClose} title={title} />
                        <StyledDiv className="KeyboardWrapper">
                            <Keyboard
                                passwordProtected={type === 'password'}
                                onEnter={onSubmit}
                                max={256}
                                onChange={onChange}
                                layout={layout}
                                layoutName={layoutName}
                                value={value}
                            />
                        </StyledDiv>
                    </StyledDiv>
                </ModalWrapper>
            </StyledDiv>
            <Backdrop show={show} onClick={onClose} />
        </>
    );
};

KeyboardModal.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    layoutName: PropTypes.string.isRequired,
    layout: PropTypes.shape({ default: PropTypes.arrayOf(PropTypes.string), shift: PropTypes.arrayOf(PropTypes.string) }).isRequired,
    type: PropTypes.string.isRequired,
};
export default KeyboardModal;
