import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { BackdropDiv } from './BackdropStyled';

const Backdrop = ({ onClick, show, zIndex, duration, onExited, onEnter }) => {
    return (
        <CSSTransition in={show} onExited={onExited} onEnter={onEnter} timeout={duration} classNames="appear" unmountOnExit>
            <BackdropDiv duration={duration} onClick={onClick} zIndex={zIndex} />
        </CSSTransition>
    );
};

Backdrop.defaultProps = {
    onClick: null,
    show: false,
    zIndex: 9,
    duration: 200,
    onEnter: null,
    onExited: null,
};

Backdrop.propTypes = {
    onClick: PropTypes.func,
    show: PropTypes.bool,
    zIndex: PropTypes.number,
    duration: PropTypes.number,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

export default Backdrop;
