import styled from 'styled-components';

const Wrapper = styled.div`
    &.PopupWrapper {
        background-color: ${(props) => props.theme.white};
        box-shadow: 0 7px 27px 0 rgba(59, 34, 14, 0.16);
        text-align: center;
        border-radius: 2rem;
        padding: 3rem;
        min-width: 15rem;
        max-width: 27.5rem;
        display: grid;
        grid-auto-flow: row;
        grid-gap: 2rem;

        @media (max-width: 1000px) {
            max-width: 90vw;
        }
    }
`;

const StyledDiv = styled.div`
    &.Controls {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        align-items: center;
        justify-content: center;
    }
    &.PopupPosition {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 20;
    }
`;

export { StyledDiv, Wrapper };
