import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { authAxios } from '../constants/axios';
import { AuthContext } from '../constants/contexts';
import { signInToFirebase } from '../firebase/signInToFirebase';
import { signOutFromFirebase } from '../firebase/signOutFromFirebase';

const auth = getAuth();

export const useCheckUser = () => {
    const [offline, setOffline] = useState(false);
    const { authData, setAuthData } = useContext(AuthContext);
    const matchData = useMatch('/login/pin');
    const indexPage = useMatch('/login/index.html');

    useEffect(() => {
        if (!authData.redirectToPin && authData.user && process.env.REACT_APP_STAGE !== 'local' && !matchData && !indexPage) {
            if (authData.claims && authData.claims.userType) {
                window.location.href = `/${authData.claims.userType}/index.html`;
            } else {
                window.location.href = '/redirect/index.html';
            }
        }
    }, [authData, matchData, indexPage]);

    const handleSignIn = useCallback(async () => {
        try {
            // try to sign in again
            const { data } = await authAxios.get('/token');

            setOffline(false);

            if (data.success) {
                const { user } = await signInToFirebase(data.accessToken);
                const { claims } = await user.getIdTokenResult();

                setAuthData((e) => ({
                    ...e,
                    user,
                    claims,
                }));
            } else {
                signOutFromFirebase();
                setAuthData((e) => ({
                    ...e,
                    user: false,
                }));
            }
        } catch (err) {
            setOffline(true);
            setAuthData((e) => ({
                ...e,
                user: false,
            }));
        } finally {
            setAuthData((e) => ({
                ...e,
                checkedForUser: true,
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let interval = null;

        if (offline) {
            interval = setInterval(handleSignIn, 1000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [handleSignIn, offline]);

    useEffect(() => {
        const handleSignOut = async () => {
            try {
                // try to sign in again
                const { data } = await authAxios.get('/token');

                setOffline(false);

                if (data.success) {
                    signInToFirebase(data.accessToken);
                } else {
                    setAuthData((e) => ({
                        ...e,
                        user: false,
                    }));
                }
            } catch (err) {
                setOffline(true);
                // have 3 retries
                setAuthData((e) => ({
                    ...e,
                    user: false,
                }));
            } finally {
                setAuthData((e) => ({
                    ...e,
                    checkedForUser: true,
                }));
            }
        };

        onAuthStateChanged(auth, (user) => {
            if (user) {
                handleSignIn(user);
            } else {
                handleSignOut();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
